<template>
  <div class="special2023 bg" :class="`bg-spl-${num}`">
    <div class="class--con">
      <class-but i-s-throw-out @forward="forward" @backOfff="onBack"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut";

export default {
  name: "special2023",
  components: {ClassBut},
  data() {
    return {
      num: 1,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log(this.$route.query.num);
      if (this.$route.query.subjectName === '书法') {
        this.$router.push({
          name: 'PageSixteenth',
          query: this.$route.query,
        })
      }else{
        this.num = this.$route.query.num || 1
      }
    },
    forward() {
      if (this.num < 11){
        this.num ++;
      }else{
        this.$router.push({
          name: 'PageSixteenth',
          query: this.$route.query,
        })
      }
    },
    onBack(){
      if (this.num > 1){
        this.num --;
      }else{
        this.$router.back();
      }
    }
  },
  beforeRouteEnter(to, from, next){
    if (from.name === 'PageSixteenth'){
      to.query.num = 11;
      next()
    }else{
      next()
    }
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.special2023 {
  &.bg-spl-1 {
    background-image: url("~@/assets/image/conference/spl1_2023.png");
  }

  &.bg-spl-2 {
    background-image: url("~@/assets/image/conference/spl2_2023.png");
  }

  &.bg-spl-3 {
    background-image: url("~@/assets/image/conference/spl3_2023.png");
  }

  &.bg-spl-4 {
    background-image: url("~@/assets/image/conference/spl4_2023.png");
  }

  &.bg-spl-5 {
    background-image: url("~@/assets/image/conference/spl5_2023.png");
  }

  &.bg-spl-6 {
    background-image: url("~@/assets/image/conference/spl6_2023.png");
  }

  &.bg-spl-7 {
    background-image: url("~@/assets/image/conference/spl7_2023.png");
  }

  &.bg-spl-8 {
    background-image: url("~@/assets/image/conference/spl8_2023.png");
  }

  &.bg-spl-9 {
    background-image: url("~@/assets/image/conference/spl9_2023.png");
  }

  &.bg-spl-10 {
    background-image: url("~@/assets/image/conference/spl10_2023.png");
  }

  &.bg-spl-11 {
    background-image: url("~@/assets/image/conference/spl11_2023.png");
  }


}
</style>
